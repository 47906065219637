/* eslint-disable no-alert */
/* eslint-disable no-nested-ternary */
import {
  Button,
  Card,
  Divider,
  Grid,
  Toolbar,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Slide,
  Stack,
  useMediaQuery,
  Avatar,
  useTheme,
} from '@mui/material';
import * as React from 'react';
import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Iconify from '../../components/Iconify';
import WithdrawForm from '../../components/forms/WithdrawForm';
import CustomModal from '../../components/modal/CustomModal';
import Spacer from '../../components/spacer';
import WithdrawalPinForm from '../../components/forms/WithdrawalPinForm';
import { fNumber } from '../../utils/formatNumber';
import isWeekend from '../../utils/weekend';

const Transition = React.forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

//   <Tabs {...props} TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }} />
// ))(({ theme }) => ({
//   '& .MuiTabs-indicator': {
//     display: 'flex',
//     justifyContent: 'center',
//     backgroundColor: 'transparent',
//   },
//   '& .MuiTabs-indicatorSpan': {
//     maxWidth: 48,
//     width: '100%',
//     backgroundColor: theme.palette.secondary.main,
//   },
// }));

// const StyledTab = styled((props) => <Tab disableRipple {...props} />)(({ theme }) => ({
//   textTransform: 'none',
//   fontWeight: theme.typography.fontWeightRegular,
//   fontSize: theme.typography.pxToRem(15),
//   marginRight: theme.spacing(1),
//   padding: theme.spacing(2),
//   color: 'rgba(0, 0, 0, 0.7)',
//   '&.Mui-selected': {
//     color: theme.palette.secondary.main,
//   },
//   '&.Mui-focusVisible': {
//     backgroundColor: 'rgba(100, 95, 228, 0.32)',
//   },
// }));

const Withdraw = (props) => {
  const { profile } = props;
  const navigate = useNavigate();
  const [openPIN, setOpenPIN] = React.useState(false);
  const [openBalance, setOpenBalance] = React.useState(false);
  const [openChooser, setOpenChooser] = React.useState(false);
  const [openDialog, setOpenDialog] = React.useState(false);
  const [openAdmin, setOpenAdmin] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [openResponse, setOpenResponse] = React.useState(false);
  const matches = useMediaQuery((theme) => theme.breakpoints.up('sm'));
  const { settings, withdrawalPlans } = useSelector((state) => state.setting);
  const { wallets } = useSelector((state) => state.auth);
  const [currIndex, setCurrIndex] = React.useState(0);
  const [fee, setFee] = React.useState(0);
  const theme = useTheme();

  const [selectedPlan, setSelectedPlan] = React.useState({});
  const [currStep, setCurrStep] = React.useState(0);
  const [selectedAmount, setSelectedAmount] = React.useState(0);

  console.log('PROFILE HERE :: ', profile);

  React.useEffect(() => {
    if (!wallets) {
      alert('You must add your crypto wallets first!');
      navigate('/dashboard')
    }
    if (isWeekend()) {
      alert('Withdrawals not allowed on weekends!');
      navigate('/dashboard')
    }
  }, [wallets]);

  return (
    <Box component={Box} px={matches ? 6 : 4} height={'92%'}>
      <CustomModal open={openResponse} setOpen={setOpenResponse} title="Customer Feedback" modalSize="sm">
        <Box sx={{ textAlign: 'start' }}>
          <Stack direction="row" alignItems="center">
            <Avatar src="/static/images/logo.png" />
            <div>
              <Typography sx={{ fontWeight: 'bolder', marginLeft: 1 }}>
                HFX Limited Support <br />
                {/* <span style={{ fontWeight: 'lighter', color: 'rgb(33 43 54 / 40%)' }}>info@</span> */}
              </Typography>
            </div>
          </Stack>
          <Divider sx={{ marginTop: 2 }} />
          <Spacer size={2} />
          <Typography variant="body1" sx={{ textTransform: 'capitalize', fontWeight: 'bolder' }} gutterBottom>
            Hi {profile?.fullName},
          </Typography>
          <Typography variant="body1" color="text.secondary" gutterBottom>
            Thank you for contacting HFX LTD, where you trade without barriers.
            <br />
            Your request is well received and currently been proccessed.
            <span style={{ textTransform: 'uppercase' }}>
              <b> (Withdrawal Request)</b>
            </span>
            <br /> Click{' '}
            <a href={`${settings[0]?.telegram}`} target="_blank" rel="noreferrer">
              here
            </a>{' '}
            to process your withdrawal with customer service.
          </Typography>
          <Spacer size={3} />
          <Typography variant="body1" color="text.secondary">
            Regards,
            <br /> HFX LTD Customer Success Team.
          </Typography>
          <Spacer size={4} />
        </Box>
      </CustomModal>

      <Dialog
        open={openDialog}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => setOpenDialog(false)}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>{'Important Notice!'}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            {`This feature is currently not available. Please use cryptocurrecny withdrawal option or contact admin for help.`}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDialog(false)}>Done</Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openBalance}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => setOpenBalance(false)}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>{'Important Notice!'}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            {`Low wallet balance! Please fund your account first to invest.`}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenBalance(false)}>Done</Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openPIN}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => setOpenPIN(false)}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>{'Initiate Withdrawal'}</DialogTitle>
        <DialogContent>
          <Box>
            <WithdrawalPinForm
              setOpenModal={setOpenAdmin}
              loading={loading}
              profile={profile}
              // setLoading={setLoading}
              // setOpenResponse={setOpenResponse}
              // setCurrStep={setCurrStep}
              // currStep={currStep}
            />
          </Box>
        </DialogContent>
      </Dialog>

      <Dialog
        open={openAdmin}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => setOpenAdmin(false)}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>{'Withdrawal PIN Purchase'}</DialogTitle>
        <DialogContent>
          <Box>
            <WithdrawForm
              setOpenModal={setOpenAdmin}
              loading={loading}
              pinPlan={selectedPlan}
              setLoading={setLoading}
              setOpenResponse={setOpenResponse}
              setCurrStep={setCurrStep}
              currStep={currStep}
              amount={selectedAmount}
              fee={fee}
            />
          </Box>
        </DialogContent>
      </Dialog>

      <Dialog
        open={openChooser}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => setOpenChooser(false)}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>{'Choose Communication Channel'}</DialogTitle>
        <DialogContent>
          <Box
            width={'100%'}
            minHeight={300}
            display={'flex'}
            flexDirection={'column'}
            justifyContent={'space-between'}
            alignItems={'stretch'}
          >
            <Typography gutterBottom>How do you want to contact admin for your withdrawal request?</Typography>
            <Toolbar />
            <Box
              width={'100%'}
              display={'flex'}
              flexDirection={'row'}
              justifyContent={'space-between'}
              alignItems={'center'}
            >
              <Button
                sx={{ px: 4, py: 1, borderRadius: 12, textTransform: 'capitalize' }}
                variant="contained"
                onClick={() => {
                  setOpenChooser(false);
                  setOpenAdmin(true);
                }}
              >
                Send Message
              </Button>
              {/* <div className="commonninja_component pid-b585d940-7003-42b4-8237-8dcc0aa50f98" /> */}
              <div className="elfsight-app-603a07cd-5eda-44db-97d7-b7426da81ed4" data-elfsight-app-lazy />
            </Box>
          </Box>
        </DialogContent>
      </Dialog>

      {profile?.withdrawalStatus === 'none' ? (
        <Card
          elevation={3}
          sx={{ boxShadow: 'revert', border: 'none' }}
          component={Box}
          bgcolor={'white'}
          p={4}
          display="flex"
          flexDirection={'column'}
          justifyContent={'start'}
        >
          <Box mb={1} display="flex" flexDirection={'row'} justifyContent={'space-between'} alignItems={'center'}>
            <Typography gutterBottom variant="h6">
              Withdrawal Pins:{' '}
            </Typography>

            <Button
              variant="contained"
              sx={{ textTransform: 'capitalize' }}
              onClick={() => navigate('/dashboard/transactions', { state: { index: 2 } })}
            >
              {matches ? 'Withdraw History' : 'History'}
            </Button>
          </Box>
          <Divider />
          <br />
          <Box sx={{ bgcolor: '#fefefe' }}>
            <Box sx={{ p: 1 }} />
          </Box>
          <Box>
            <Box display="flex" flexDirection={'column'} justifyContent={'start'}>
              {/* <Typography gutterBottom>Cryptocurrency</Typography> */}
              <Grid container spacing={2}>
                {withdrawalPlans &&
                  withdrawalPlans?.map((item, index) => (
                    <Grid key={item?.value} item xs={12} sm={6} md={4}>
                      <Box position="relative" width={'100%'} height="100%">
                        <Button
                          variant="contained"
                          sx={{
                            bgcolor: currIndex === index ? theme.palette.secondary.dark : theme.palette.text.disabled,
                            position: 'relative',
                            width: '100%',
                            height: '100%',
                            zIndex: 1,
                          }}
                          fullWidth
                          onClick={() => {
                            setCurrIndex(index);
                            setSelectedPlan(item);
                            setSelectedAmount(item?.amount);
                            const divi = settings[0]?.deposit_fee / 100;
                            const calcFee = item.amount * divi;
                            setFee(calcFee);
                            setOpenAdmin(true);
                          }}
                        >
                          <Box
                            pt={3}
                            px={2}
                            pb={2}
                            display="flex"
                            flexDirection="column"
                            justifyContent="center"
                            alignItems="center"
                          >
                            <Iconify icon="twemoji:coin" sx={{ fontSize: 48 }} />
                            <Typography mt={2}>{`${item?.name}`}</Typography>
                            <Typography>{`$${fNumber(item?.amount)}`}</Typography>
                          </Box>
                        </Button>
                        {currIndex === index && (
                          <Iconify
                            icon="solar:check-circle-bold-duotone"
                            sx={{
                              color: 'red',
                              width: 36,
                              height: 36,
                              position: 'absolute',
                              top: -6,
                              right: -4,
                              zIndex: 3,
                            }}
                          />
                        )}
                      </Box>
                    </Grid>
                  ))}
                {/* <Grid item xs={12} sm={6} md={6}>
                <Box display="flex" flexDirection={'column'} justifyContent={'start'} alignItems={'start'}>
                  <Box
                    component={Button}
                    variant="outlined"
                    width={'100%'}
                    display="flex"
                    sx={{ px: 2, py: 2, textTransform: 'capitalize' }}
                    flexDirection={'row'}
                    justifyContent={'space-between'}
                    alignItems={'center'}
                    onClick={() => {
                      setSelectedCrypto({ name: 'usdt', logo: '/usdt-logo.png' });
                      setTimeout(() => {
                        setOpenAdmin(true);
                      }, 500);
                    }}
                  >
                    <Typography>Tether (USDT)</Typography>
                    <img src="/usdt-logo.png" alt="" width={32} />
                  </Box>
                  <br />
                  <Box
                    component={Button}
                    variant="outlined"
                    width={'100%'}
                    display="flex"
                    sx={{ px: 2, py: 1.3, textTransform: 'capitalize' }}
                    flexDirection={'row'}
                    justifyContent={'space-between'}
                    alignItems={'center'}
                    onClick={() => {
                      setSelectedCrypto({ name: 'sol', logo: '/solana-logo.png' });
                      setTimeout(() => {
                        setOpenAdmin(true);
                      }, 500);
                    }}
                  >
                    <Typography>Solana (SOL)</Typography>
                    <img src="/solana-logo.png" alt="" width={40} />
                  </Box>
                </Box>
              </Grid> */}
              </Grid>
            </Box>
          </Box>
          <Toolbar />
          <Toolbar />
        </Card>
      ) : profile?.withdrawalStatus === 'processing' ? (
        <Card
          elevation={3}
          sx={{ boxShadow: 'revert', border: 'none' }}
          component={Box}
          bgcolor={'white'}
          p={4}
          height={'90%'}
          display="flex"
          flexDirection={'column'}
          justifyContent={'start'}
        >
          <Box p={2} height={'100%'} display="flex" flexDirection="column" justifyContent="center" alignItems="center">
            <Iconify icon="line-md:downloading-loop" sx={{ fontSize: 96 }} />
            <Typography>Withdrawal Request In Review</Typography>
          </Box>
        </Card>
      ) : profile?.withdrawalStatus === 'approved' || profile?.withdrawalStatus === 'ready' ? (
        <Card
          elevation={3}
          sx={{ boxShadow: 'revert', border: 'none' }}
          component={Box}
          bgcolor={'white'}
          p={4}
          height={'90%'}
          display="flex"
          flexDirection={'column'}
          justifyContent={'start'}
        >
          <Box p={2} height={'100%'} display="flex" flexDirection="column" justifyContent="center" alignItems="center">
            <Iconify icon="pepicons-pop:checkmark-filled" sx={{ fontSize: 96 }} color="green" />
            <Typography gutterBottom>Withdrawal pin purchased</Typography>
            <Button variant="contained" sx={{ p: 2, textTransform: 'capitalize' }} onClick={() => setOpenPIN(true)}>
              Complete Withdrawal
            </Button>
          </Box>
        </Card>
      ) : (
        <Card
          elevation={3}
          sx={{ boxShadow: 'revert', border: 'none' }}
          component={Box}
          bgcolor={'white'}
          p={4}
          height={'90%'}
          display="flex"
          flexDirection={'column'}
          justifyContent={'start'}
        >
          <Box p={2} height={'100%'} display="flex" flexDirection="column" justifyContent="center" alignItems="center">
            <Iconify icon="pepicons-pop:checkmark-filled" sx={{ fontSize: 100 }} color="green" />
            <Typography>Withdrawal Request Sent</Typography>
            <Typography variant="body2" gutterBottom>
              Awaiting Admin Approval
            </Typography>
          </Box>
        </Card>
      )}
    </Box>
  );
};

export default Withdraw;
