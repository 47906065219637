import React, { useEffect, useState } from 'react';
import { faker } from '@faker-js/faker';
import { Avatar, Box, Typography } from '@mui/material';

const RandomUserGenerator = () => {
  const [user, setUser] = useState({ firstName: '', lastName: '', image: '' });

  useEffect(() => {
    const interval = setInterval(() => {
      // Generate random first and last names using Faker.js
      const randomFirstName = faker.name.firstName();
      const randomLastName = faker.name.lastName();
      const randomImage = faker.image.avatar();
      setUser({ firstName: randomFirstName, lastName: randomLastName, image: randomImage });
    }, 10000);

    return () => clearInterval(interval);
  }, []);

  return (
    <Box p={1} display={'flex'} flexDirection="row" justifyContent="start" alignItems="center">
      {/* <Avatar src={user?.image} alt="" width={48} /> */}
      <Typography px={1} fontWeight={700} >{user.firstName}</Typography>
      <Typography fontWeight={700} >{user.lastName}</Typography>
      <Typography px={1}>just invested</Typography>
    </Box>
  );
};

export default RandomUserGenerator;
