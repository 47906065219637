import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { Box, Toolbar, useMediaQuery } from '@mui/material';
import EmptyCard from '../cards/EmptyCard';
import LoadingBackdrop from '../loading/Backdrop';
import CustomModal from '../modal/CustomModal';
import WalletForm from './WalletForm'
import WalletsTable from '../tables/wallets';

function PaymentForm(props) {
  const { profile, mutate } = props;
  const [loading, setLoading] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const { wallets } = useSelector((state) => state.auth);
  const matches = useMediaQuery((theme) => theme.breakpoints.up('sm'));

  console.log('wALleTS  ::::: ', wallets);

  const handleBank = () => {
    setOpenModal(true);
  };

  return (
    <>
      {loading && <LoadingBackdrop open={loading} setOpen={setLoading} />}
      <CustomModal open={openModal} setOpen={setOpenModal} title="Add Crypto Wallet">
        <WalletForm mutate={mutate} loading={loading} setLoading={setLoading} setOpenModal={setOpenModal} />
      </CustomModal>
      <Grid container spacing={2}>
        <Grid item sm={6} md={7} xs={12}>
          <Typography variant="h5">Wallet Details</Typography>
          <Typography variant="body2" color="text.secondary">
            This is a list of all your crypto wallets you can use to receive funds into.
          </Typography>
          <Box p={1} />
          <WalletsTable />
        </Grid>
        <Grid item sm={6} md={5} xs={12}>
          <Toolbar />
          <EmptyCard title="Add Wallet" handleAction={handleBank} />
        </Grid>
      </Grid>
    </>
  );
}

export default PaymentForm;
