import PropType from 'prop-types';
import { useEffect, useState } from 'react';
import useMediaQuery from '@mui/material/useMediaQuery';
import { styled } from '@mui/material/styles';
import { sentenceCase } from 'change-case';
// import Stack from '@mui/material/Stack';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
// components
// import useSWR from 'swr'
import { Box, Card, Toolbar, useTheme } from '@mui/material';
import { useSelector } from 'react-redux';
import { Line, Doughnut } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Filler,
} from 'chart.js';

import InfoCard, { MobileInfoCard, StyledCard } from '../../components/cards/InfoCard';
import Advert from '../../components/advert';
import PortfolioCard from '../../components/cards/PortfolioCard';
import SideCard from '../../components/cards/SideCard';
import RandomUserGenerator from '../../utils/user-generator';

const ColoredTypography = styled(Typography)(({ theme }) => ({
  color: theme.palette.mode === 'light' ? theme.palette.primary.darker : theme.palette.primary.lighter,
}));

ChartJS.register(ArcElement, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Filler, Legend);

function Overview(props) {
  const { profile } = props;
  const matches = useMediaQuery((theme) => theme.breakpoints.up('sm'));
  const theme = useTheme();
  const [deviceType, setDeviceType] = useState('mobile');
  const { myRequest, myDeposits, myWithdraws } = useSelector((state) => state.request);
  const { activeInvestment } = useSelector((state) => state.investment);
  const { graphers, myTransaction } = useSelector((state) => state.transaction);
  const [latestRequest, setLatestRequest] = useState();
  const [chartData, setChartData] = useState([]);
  const [last5, setLast5] = useState([]);

  const xs = useMediaQuery(theme.breakpoints.only('xs'));
  const sm = useMediaQuery(theme.breakpoints.only('sm'));

  console.log('GRAPHERS :: ', graphers);

  useEffect(() => {
    if (xs) {
      setDeviceType('mobile');
    } else if (sm) {
      setDeviceType('tablet');
    } else {
      setDeviceType('pc');
    }
  }, [sm, xs]);

  useEffect(() => {
    if (myRequest) {
      setLatestRequest(myRequest?.docs[0]);
    }
  }, [myRequest, activeInvestment]);

  useEffect(() => {
    if (graphers) {
      const data = graphers?.map((item) => item?.value);

      console.log('CHART DATA  ::: ::: ', data);

      setChartData(data);
    }

    if (myTransaction) {
      const copyTransaction = myTransaction?.docs;
      const truncate = copyTransaction?.slice(0, 5);
      setLast5(truncate);
      console.log('MY TRANSACTIONS ::: ', truncate);
    }
  }, [graphers, myTransaction]);

  const data = {
    labels: ['Investments', 'Withdrawals'],
    datasets: [
      {
        label: '# of transactions',
        data: [myDeposits?.totalDocs ?? 0, myWithdraws?.totalDocs ?? 0],
        backgroundColor: [
          'rgba(255, 99, 132, 0.2)',
          'rgba(54, 162, 235, 0.2)',
          'rgba(255, 206, 86, 0.2)',
          'rgba(75, 192, 192, 0.2)',
          'rgba(153, 102, 255, 0.2)',
          'rgba(255, 159, 64, 0.2)',
        ],
        borderColor: [
          'rgba(255, 99, 132, 1)',
          'rgba(54, 162, 235, 1)',
          'rgba(255, 206, 86, 1)',
          'rgba(75, 192, 192, 1)',
          'rgba(153, 102, 255, 1)',
          'rgba(255, 159, 64, 1)',
        ],
        borderWidth: 1,
      },
    ],
  };

  const options2 = {
    responsive: true,
    plugins: {
      title: {
        display: true,
        text: 'Transaction stats',
      },
    },
  };

  const dataZee = {
    labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
    // Information about the dataset
    datasets: [
      {
        label: 'Investment Growth',
        backgroundColor: [
          'rgba(255, 99, 132, 0.2)',
          'rgba(54, 162, 235, 0.2)',
          'rgba(255, 206, 86, 0.2)',
          'rgba(75, 192, 192, 0.2)',
          'rgba(153, 102, 255, 0.2)',
          'rgba(255, 159, 64, 0.2)',
        ],
        borderColor: theme.palette.secondary.main,
        borderWidth: 1,
        data: chartData,
        fill: {
          target: 'origin',
          above: theme.palette.secondary.light, // Adjust the opacity as needed
        },
        pointRadius: 0,
      },
    ],
  };

  // Configuration options
  const optionsZee = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        grid: {
          display: false, // Remove the x-axis grid lines
        },
      },
      y: {
        grid: {
          display: false, // Remove the y-axis grid lines
        },
        ticks: {
          stepSize: 10, // Set the step size to 10
        },
      },
    },
  };

  return (
    <Box>
      {profile && (
        <Box component={Box} px={matches ? 6 : 4}>
          {matches && (
            <ColoredTypography variant="h4" sx={{ mb: 3 }}>
              Hello {sentenceCase(profile?.firstName)},
            </ColoredTypography>
          )}
          <Grid
            container
            spacing={matches ? 2 : 1}
            display={'flex'}
            flexDirection={'row'}
            justifyContent={'start'}
            alignItems={'stretch'}
          >
            <Grid item sm={6} md={8} lg={9} xs={12} height={'100%'} p={2}>
              <Box display={'flex'} flexDirection={'column'} width={'100%'}>
                {matches ? (
                  <InfoCard
                    profile={profile}
                    activeInvestment={activeInvestment}
                    deviceType={deviceType}
                    chartComponent={<Line options={optionsZee} data={dataZee} />}
                  />
                ) : (
                  <MobileInfoCard
                    profile={profile}
                    activeInvestment={activeInvestment}
                    request={myRequest}
                    deviceType={deviceType}
                    depositCount={myDeposits?.docs?.length}
                    withdrawCount={myWithdraws?.docs?.length}
                    chartComponent={<Line options={optionsZee} data={dataZee} />}
                    barComponent={<></>}
                  />
                )}
                <br />
                <PortfolioCard
                  title={'SmartSaver Portfolio'}
                  fundsize={'32'}
                  roi="+5%"
                  image={''}
                  risk={'Low'}
                  matches={matches}
                />
                <br />
                <PortfolioCard
                  title={'WealthBuilder Portfolio'}
                  fundsize={'32'}
                  roi="+5%"
                  image={''}
                  risk={'Low'}
                  matches={matches}
                />
                <br />
                {!matches && <Doughnut data={data} options={options2} />}
              </Box>
            </Grid>

            {/* RIGHT HAND SIDE */}
            <Grid item sm={6} md={4} lg={3} xs={12} sx={{ height: '100%', display: !matches ? 'none' : 'flex' }}>
              <Box
                height={'100%'}
                flex={1}
                display={'flex'}
                flexDirection={'column'}
                justifyContent={'start'}
                alignItems={'stretch'}
              >
                <Advert
                  title={['HFX LIMITED', '']}
                  featuredImage={'/static/images/tsakeoff.jpg'}
                  textColor="white"
                  overlay
                  height={'100%'}
                />
                <Box p={1} />
                <StyledCard sx={{ width: '100%' }}>
                  <RandomUserGenerator />
                </StyledCard>
                {/* <Doughnut data={data} options={options2} /> */}
                <Box p={1} />
                <SideCard items={last5} matches={matches} title={''} subTitle={'Trade at peace'} />
                <Box p={1} />
              </Box>
            </Grid>
          </Grid>
          {matches ? <Toolbar /> : <br />}
        </Box>
      )}
    </Box>
  );
}
export default Overview;

Overview.propTypes = {
  profile: PropType.object,
};
