import { createSlice } from "@reduxjs/toolkit";

export const paymentMethodSlice = createSlice({
  name: "paymentMethod",
  initialState: {
    paymentMethods: [],
  },
  reducers: {
    setPaymentMethods: (state, action) => {
      state.paymentMethods = action.payload;
    },
  },
});

export const { setPaymentMethods } = paymentMethodSlice.actions;

export default paymentMethodSlice.reducer;
