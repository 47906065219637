import { Box, Container, useMediaQuery } from '@mui/material';
import React from 'react'

const DepositHistory = (props) => {
    const { profile } = props;
    const matches = useMediaQuery(theme => theme.breakpoints.up('sm'));


  return (
    <Box component={matches ? Container : Box} p={2}  maxWidth='lg' >DepositHistory</Box>
  )
}

export default DepositHistory