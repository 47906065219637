import useSWR from 'swr';

export default function useWithdrawalPlan() {
  const { data, mutate, error } = useSWR('/withdrawal/all');
  console.log('WALLET PINS HERE :: ', data);
  const loading = !data && !error;
  const loggedOut =
    (error && error?.message === 'No token provided.') ||
    error?.response?.status === 401 ||
    error?.response?.status === 403 ||
    error?.response?.data?.message === 'No user found!';

  return {
    loading,
    loggedOut,
    data, 
    mutate,
  };
}
