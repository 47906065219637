import { Avatar, Box, ListItem, Stack, styled, Typography, useTheme } from '@mui/material';
import React from 'react';
import { StyledCard } from './InfoCard';
import Iconify from '../Iconify';
import colorVariant from '../../utils/colorVariant';
import iconVariant from '../../utils/iconVariant';
import formatCurrency from '../../utils/formatCurrency';

const StyledTypography = styled(Typography)(({ theme }) => ({
  textTransform: 'capitalize',
  [theme.breakpoints.down('sm')]: {
    fontSize: 12,
  },
}));

const StyledAvatar = styled(Avatar)(({ theme }) => ({
  width: 30,
  height: 30,
  marginRight: 5,
  [theme.breakpoints.down('sm')]: {
    width: 20,
    height: 20,
  },
}));

const SideCard = ({ title, subTitle, items, matches }) => {
  console.log('');
  const theme = useTheme();

  return (
    <Box display="flex" flexDirection={'column'} justifyContent={'start'} sx={{ width: '100%' }}>
      <Typography gutterBottom variant={matches ? 'h4' : 'h5'}>
        {title}
      </Typography>
      <StyledCard sx={{ width: '100%' }}>
        <Box display="flex" flexDirection={'column'} justifyContent={'start'} alignItems={'start'} mb={1}>
          <Typography color={'lightgray'} variant={matches ? 'body1' : 'body2'}>
            {subTitle}
          </Typography>
          {items?.map((item, index) => (
            <ListItem
              key={index}
              sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'start', alignItems: 'center' }}
            >
              <Stack direction="row" alignItems="center">
                <StyledAvatar sx={{ bgcolor: colorVariant(item?.status) }}>
                  <Iconify icon={iconVariant(item?.status)} />
                </StyledAvatar>
              </Stack>
              <StyledTypography px={2} variant="body2">{formatCurrency(item?.amount)}</StyledTypography>
              <StyledTypography variant="body2">{new Date(item?.createdAt).toDateString()}</StyledTypography>
            </ListItem>
          ))}
        </Box>
      </StyledCard>
    </Box>
  );
};

export default SideCard;
