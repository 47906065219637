/* eslint-disable no-nested-ternary */
/* eslint-disable react/prop-types */
import * as Yup from 'yup';
import { useFormik, Form, FormikProvider } from 'formik';
import toast, { Toaster } from 'react-hot-toast';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import LoadingButton from '@mui/lab/LoadingButton';
import {
  Button,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  NativeSelect,
  OutlinedInput,
  Typography,
} from '@mui/material';
import { useState } from 'react';
import QRCode from 'react-qr-code';
import { useSelector } from 'react-redux';
import { fNumber } from '../../utils/formatNumber';
import APIService from '../../service';
import Iconify from '../Iconify';


const depositSchema = Yup.object().shape({
  crypto: Yup.string().required('Select payment crypto-currency'),
});

function WithdrawForm(props) {
  const { loading, setLoading, setOpenModal, setOpenResponse, currStep, setCurrStep, fee, amount, pinPlan } = props;
  const { settings } = useSelector((state) => state.setting);
  const { profile } = useSelector((state) => state.auth);
  const { cryptos } = useSelector((state) => state.crypto);
  const [crypto, setCrypto] = useState({})  

  const formik = useFormik({
    initialValues: {
      crypto: '',
    },
    validationSchema: depositSchema,
    onSubmit: async (values) => {
      if (currStep === 0) {
        setCurrStep(1);
      } else {
        setLoading(true);
        // Create pin purchase request here
        const payload = {
          user: profile?.id ?? profile?._id,
          crypto: crypto?.id,
          plan: pinPlan?.id,
          amount: (amount + fee)
        };

        const response = APIService.post('/pin/purchase', payload);

        toast.promise(response, {
          loading: 'loading',
          success: (res) => {
            setLoading(false);
            setOpenModal(false);
            setOpenResponse(true);
            setCurrStep(0);
            return `${res.data?.message || 'Request submitted successfully'}`;
          },
          error: (err) => {
            setLoading(false);
            return err?.response?.data?.message || err?.message || 'Something went wrong, try again.';
          },
        });
      }
    },
  });

  const { handleSubmit, values, setFieldValue } = formik;

  return (
    <FormikProvider value={formik}>
      {currStep === 0 ? (
        <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
          <Typography fontSize={13} >
            Select the cryptocurrency platform you want to make pin purchase payment to
          </Typography>
          <Typography fontSize={13} gutterBottom  >
            Once payment is made, click on the <strong>'I have paid'</strong> button. <br /> <span>Your account will be credited upon confirmation of reception of funds </span>
          </Typography>
          <Box py={2}>
            <FormControl fullWidth>
              <InputLabel htmlFor="subject" shrink margin="dense" sx={{ paddingRight: 24 }}>
                Purchase PIN With
              </InputLabel>
              <NativeSelect
                input={
                  <OutlinedInput
                    label="Purchase PIN With"
                    variant="outlined"
                    value={values.crypto}
                    id="subject"
                    onChange={(e) => {
                      setFieldValue('crypto', e.target.value)
                      const filtered = cryptos?.filter((elem) => elem?.id === e.target.value);
                      console.log("lkbkDDF ", filtered);
                      
                      setCrypto(filtered[0]);
                    }}
                  />
                }
                id="subject"
              >
                {cryptos?.map((item) => (
                  <option key={item?.id ?? item?._id} value={item?.id ?? item?._id}>
                    {item.name}
                  </option>
                ))}
              </NativeSelect>
            </FormControl>

            <Box p={1} />
            <Typography gutterBottom>
              {`Fee: (${settings[0]?.deposit_fee}%)`} <span style={{ fontWeight: 900, paddingLeft: 16 }}>${fee}</span>{' '}
            </Typography>
            <Box p={1} />

            <LoadingButton fullWidth size="large" type="submit" variant="contained" loading={loading}>
              {`Pay $${fNumber(amount + fee)}`}
            </LoadingButton>
            <Toaster />
          </Box>
        </Form>
      ) : currStep === 1 ? (
        <Box pb={2} display="flex" flexDirection="column" justifyContent="start" alignItems="center">
          <Box width="100%" display="flex" flexDirection="row" justifyContent="stretch" alignItems="center">
            <IconButton onClick={() => setCurrStep(0)}>
              <Iconify icon="mdi:arrow-left-thin" />
            </IconButton>
            <Typography
              alignContent={'center'}
              margin={'0px auto'}
              textAlign="center"
              variant="h6"
              fontWeight={800}
              gutterBottom
            >
              Confirm Payment
            </Typography>
          </Box>
          <Typography>Amount</Typography>
          <Typography variant="h4" fontWeight={900} gutterBottom>
            {`$${fNumber(amount + fee)}`}
          </Typography>
          <Box p={2} />
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={5}>
              <Typography pr={2}>Payment Method</Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={7}>
              <Box width="100%" display="flex" flexDirection="row" justifyContent="start" alignItems="center">
                <Typography px={1}>{`Cryptocurrency ${crypto?.name}`}</Typography>
                <img alt="" src={crypto?.logo} width={32} />
              </Box>
            </Grid>
          </Grid>

          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={5}>
              <Typography pr={2}>Currency</Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={7}>
              <Typography pl={1}>{`USD`}</Typography>
            </Grid>
          </Grid>

          <Box p={1} />
          <Typography fontSize={14} fontFamily={'serif'}>
            Please review the information carefully and make sure everything is correct before submitting.
          </Typography>
          <Box p={2} />
          <Button
            variant="contained"
            sx={{ p: 1, textTransform: 'capitalize' }}
            fullWidth
            onClick={() => setCurrStep(2)}
          >
            Proceed to Payment
          </Button>
        </Box>
      ) : (
        <Box pb={2} display="flex" flexDirection="column" justifyContent="start">
          <Box width="100%" display="flex" flexDirection="row" justifyContent="stretch" alignItems="center">
            <IconButton onClick={() => setCurrStep(1)}>
              <Iconify icon="mdi:arrow-left-thin" />
            </IconButton>
            <Typography fontSize={14} fontFamily={'serif'} gutterBottom>
              {`You are going to deposit $${fNumber(pinPlan?.amount + fee)}`}
            </Typography>
          </Box>
          <Box p={1} />
          <Typography>
            {`Send exactly`} <span style={{ fontWeight: 900 }}>{`$${fNumber(pinPlan?.amount + fee)} `}</span> to
          </Typography>
          <TextField variant="outlined" size="small" fullWidth value={`${crypto?.wallet_address}`} disabled />
          <Box p={2} />
          <div style={{ height: 'auto', margin: '0 auto', maxWidth: 100, width: '100%' }}>
            <QRCode
              size={320}
              style={{ height: 'auto', maxWidth: '100%', width: '100%' }}
              value={`${crypto?.wallet_address}`}
              viewBox={`0 0 360 360`}
            />
          </div>
          <Box p={2} />
          <Button variant="contained" sx={{ textTransform: 'capitalize' }} onClick={() => handleSubmit()}>
            I have made payment
          </Button>
          <Box p={1} />
          <Typography fontSize={13} textAlign={'center'}>
            Your payment will be completed once it is confirmed by the admin. Confirmation time can vary and depends on
            the fee paid. All cryptocurrency transactions are performed on the side of third-party crypto providers.
          </Typography>
        </Box>
      )}
    </FormikProvider>
  );
}

export default WithdrawForm;
