import { Box, Button, Divider, Typography, useTheme } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { StyledCard } from './InfoCard';

const PortfolioCard = ({ title, roi, risk, fundsize, image, matches }) => {
  console.log('');
  const theme = useTheme();
  const navigate = useNavigate();

  return (
    <StyledCard sx={{ width: '100%' }}>
      <Box display="flex" flexDirection={'column'} justifyContent={'start'} p={2}>
        {/* Action bar */}
        <Box display="flex" flexDirection={'row'} justifyContent={'space-between'} alignItems={'center'} mb={1}>
          <Typography>Equity</Typography>
          <Button
            variant="outlined"
            onClick={() => navigate('/dashboard/investment-plans')}
            sx={{
              px: 2,
              textTransform: 'capitalize',
              borderColor: theme.palette.secondary.main,
              color: theme.palette.secondary.main,
            }}
          >
            Invest now
          </Button>
        </Box>
        <Typography variant={matches ? 'h4' : 'h5'}>{title}</Typography>
        <Divider sx={{ my: 1 }} />
        <Box display="flex" flexDirection={'row'} justifyContent={'start'} alignItems={'center'}>
          <Box flex={1} display="flex" flexDirection={'column'} justifyContent={'start'} alignItems={'start'}>
            <Typography color={'lightgray'} variant={matches ? 'body1' : 'body2'}>
              Fund Size
            </Typography>
            <Typography color={'black'} fontWeight={600} variant="h6">
              {fundsize}
            </Typography>
          </Box>
          <Box flex={1} display="flex" flexDirection={'column'} justifyContent={'start'} alignItems={'start'}>
            <Typography color={'lightgray'} variant={matches ? 'body1' : 'body2'}>
              ROI
            </Typography>
            <Typography color={'black'} fontWeight={600} variant="h6">
              {roi}
            </Typography>
          </Box>
          <Box flex={1} display="flex" flexDirection={'column'} justifyContent={'start'} alignItems={'start'}>
            <Typography color={'lightgray'} variant={matches ? 'body1' : 'body2'}>
              Risk
            </Typography>
            <Typography color={'black'} fontWeight={600} variant="h6">
              {risk}
            </Typography>
          </Box>
        </Box>
      </Box>
    </StyledCard>
  );
};

export default PortfolioCard;
