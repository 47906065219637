import useSWR from 'swr';

export default function useWallets(userId) {
  const { data, mutate, error } = useSWR(`/crypto/wallet/user/all?userId=${userId}`);

  console.log('MY WALLETS HERE :: ', data);
  const loading = !data && !error;
  const loggedOut =
    (error && error?.message === 'No token provided.') ||
    error?.response?.status === 401 ||
    error?.response?.status === 403 ||
    error?.response?.data?.message === 'No user found!' ||
    data?.accountStatus === 'frozen';

  return {
    loading,
    loggedOut,
    data,
    mutate,
  };
}

// APIService.fetcher(
//   `crypto/wallet/user/all?userId=${selected?.row?.id ?? selected?.row?.id}`
// ).then((response) => {
//   console.log("USER WALLETS ::: ", response);
//   setWallets(response);
// });
