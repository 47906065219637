// Example in https://nagix.github.io/chartjs-plugin-streaming/master/tutorials/react/stream.html
// is not correct!  Use Chart from chart.js instead.
import React from 'react';
import { Chart } from 'chart.js';
import StreamingPlugin from '@ambiqai/chartjs-plugin-streaming';
import { Line } from 'react-chartjs-2';
import 'chartjs-adapter-luxon';
import { useTheme } from '@mui/material';

try {
  Chart.register(StreamingPlugin);
} catch (error) {
  console.log();
}

export default function Stream() {
  const theme = useTheme();
  return (
    <Line
      style={{ width: '100%', minHeight: 225 }}
      data={{
        datasets: [
          {
            label: '',
            backgroundColor: theme.palette.secondary.light,
            cubicInterpolationMode: 'monotone',
            fill: true,
            data: [],
          },
          // {
          //   label: "Dataset 2",
          //   backgroundColor: "rgba(54, 162, 235, 0.5)",
          //   borderColor: "rgb(54, 162, 235)",
          //   cubicInterpolationMode: "monotone",
          //   fill: true,
          //   data: [],
          // },
        ],
      }}
      options={{
        scales: {
          x: {
            type: 'realtime',
            grid: {
              color: 'rgba(0, 0, 0, 0)',
            },
            realtime: {
              delay: 2000,
              onRefresh: (chart) => {
                chart.data.datasets.forEach((dataset) => {
                  dataset.data.push({
                    x: Date.now(),
                    y: Math.random(),
                  });
                });
              },
            },
          },
          y: {
            grid: {
              color: 'rgba(0, 0, 0, 0)',
            },
          }
        },
      }}
    />
  );
}
