/* eslint-disable no-nested-ternary */
/* eslint-disable react/prop-types */
import * as Yup from 'yup';
import { useFormik, Form, FormikProvider } from 'formik';
import toast, { Toaster } from 'react-hot-toast';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import LoadingButton from '@mui/lab/LoadingButton';
import { Button, Grid, IconButton, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import QRCode from 'react-qr-code';
import { useSelector } from 'react-redux';
import APIService from '../../service';
import Iconify from '../Iconify';
// import { useSWRFetch } from '../../hooks';

const StyledTextField = styled(TextField)(() => ({
  marginBottom: 10,
  marginTop: 10,
}));

const depositSchema = Yup.object().shape({
  amount: Yup.number().required('Enter deposit amount in USD'),
  // comment: Yup.string().required('Your wallet address is required')
});

const amts = [
  { label: '$100', value: 100 },
  { label: '$200', value: 200 },
  { label: '$500', value: 500 },
  { label: '$1,000', value: 1000 },
  { label: '$2,500', value: 2500 },
  { label: '$5,000', value: 5000 },
  { label: '$10,000', value: 10000 },
];

function DepositForm2(props) {
  const { payment, loading, setLoading, setOpenModal, setOpenResponse, currStep, setCurrStep } = props;
  const { settings } = useSelector((state) => state.setting);
  const [currIndex, setCurrIndex] = useState(0);
  const [fee, setFee] = useState(0);
  const theme = useTheme();

  useEffect(() => {
    if (settings) {
      // console.log("FEE :: ", payment);

      const calcFee = 100 * (settings[0]?.deposit_fee / 100);
      setFee(calcFee);
    }
  }, [settings]);

  const formik = useFormik({
    initialValues: {
      amount: 100,
      comment: 'customer deposit',
      investmentPlan: '',
    },
    validationSchema: depositSchema,
    onSubmit: async (values) => {
      if (currStep === 0) {
        setCurrStep(1);
      } else {
        setLoading(true);

        const payload = {
          ...values,
          currency: 'USD',
          status: 'pending',
          type: 'deposit',
        };

        const response = APIService.post('/request/create', payload);

        toast.promise(response, {
          loading: 'loading',
          success: (res) => {
            setLoading(false);
            setOpenModal(false);
            setOpenResponse(true);
            setCurrStep(0);
            return `${res.data?.message || 'Request submitted successfully'}`;
          },
          error: (err) => {
            setLoading(false);
            return err?.response?.data?.message || err?.message || 'Something went wrong, try again.';
          },
        });
      }
    },
  });

  const { errors, touched, handleSubmit, getFieldProps } = formik;
  return (
    <FormikProvider value={formik}>
      {currStep === 0 ? (
        <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
          <Grid container spacing={1}>
            {amts.map((item, index) => (
              <Grid key={item?.value} item xs={12} sm={6} md={4}>
                <Box position="relative" width={'100%'} height="100%">
                  <Button
                    variant="contained"
                    sx={{
                      bgcolor: currIndex === index ? theme.palette.secondary.dark : theme.palette.text.disabled,
                      position: 'relative',
                      width: '100%',
                      height: '100%',
                      zIndex: 1,
                    }}
                    fullWidth
                    onClick={() => {
                      setCurrIndex(index);
                      formik.setFieldValue('amount', item.value);
                      const divi = (settings[0]?.deposit_fee / 100);
                      const calcFee = item.value * divi;
                      setFee(calcFee);
                    }}
                  >
                    {`${item?.label}`}
                  </Button>
                  {currIndex === index && (
                    <Iconify
                      icon="solar:check-circle-bold-duotone"
                      sx={{ color: 'red', width: 36, height: 36, position: 'absolute', top: -6, right: -4, zIndex: 3 }}
                    />
                  )}
                </Box>
              </Grid>
            ))}

            <Grid item xs={12} sm={6} md={4}>
              <TextField
                size="small"
                value={formik.values.amount}
                onChange={(e) => {
                  formik.setFieldValue('amount', parseInt(e.target.value, 10));
                  const divi = (settings[0]?.deposit_fee / 100);
                  const calcFee = parseInt(e.target.value, 10) * divi;
                  console.log("SAS ::", calcFee);
                  
                  setFee(calcFee);
                }}
                placeholder="Enter amount"
              />
            </Grid>
          </Grid>
          <Box p={0.5} />
          <Box py={1}>
            <StyledTextField
              fullWidth
              label="Payment-currency"
              value={payment?.name?.toUpperCase()}
              disabled
              InputProps={{
                startAdornment: <img alt="" src={payment.logo} width={36} style={{ padding: 8.0 }} />,
              }}
              error={Boolean(touched.amount && errors.amount)}
              helperText={touched.amount && errors.amount}
            />

            <Box p={1} />
            <Typography gutterBottom>
              {`Fee: (${settings[0]?.deposit_fee}%)`} <span style={{ fontWeight: 900, paddingLeft: 16 }}>${fee}</span>{' '}
            </Typography>
            <Box p={1} />

            <LoadingButton fullWidth size="large" type="submit" variant="contained" loading={loading}>
              {`Pay $${formik.values.amount + fee}`}
            </LoadingButton>
            <Toaster />
          </Box>
        </Form>
      ) : currStep === 1 ? (
        <Box pb={2} display="flex" flexDirection="column" justifyContent="start" alignItems="center">
          <Box width="100%" display="flex" flexDirection="row" justifyContent="stretch" alignItems="center">
            <IconButton onClick={() => setCurrStep(0)}>
              <Iconify icon="mdi:arrow-left-thin" />
            </IconButton>
            <Typography
              alignContent={'center'}
              margin={'0px auto'}
              textAlign="center"
              variant="h6"
              fontWeight={800}
              gutterBottom
            >
              Confirm Payment
            </Typography>
          </Box>
          <Typography>Amount</Typography>
          <Typography variant="h4" fontWeight={900} gutterBottom>
            {`$${formik.values.amount + fee}`}
          </Typography>
          <Box p={2} />
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={5}>
              <Typography pr={2}>Payment Method</Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={7}>
              <Box width="100%" display="flex" flexDirection="row" justifyContent="start" alignItems="center">
                <Typography px={1}>{` ${payment?.name}`}</Typography>
                <img alt="" src={payment?.logo} width={32} />
              </Box>
            </Grid>
          </Grid>

          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={5}>
              <Typography pr={2}>Currency</Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={7}>
              <Typography pl={1}>{`USD`}</Typography>
            </Grid>
          </Grid>

          <Box p={1} />
          <Typography fontSize={14} fontFamily={'serif'}>
            Please review the information carefully and make sure everything is correct before submitting.
          </Typography>
          <Box p={2} />
          <Button
            variant="contained"
            sx={{ p: 1, textTransform: 'capitalize' }}
            fullWidth
            onClick={() => setCurrStep(2)}
          >
            Proceed to Payment
          </Button>
        </Box>
      ) : (
        <Box pb={2} display="flex" flexDirection="column" justifyContent="start">
          <Box width="100%" display="flex" flexDirection="row" justifyContent="stretch" alignItems="center">
            <IconButton onClick={() => setCurrStep(1)}>
              <Iconify icon="mdi:arrow-left-thin" />
            </IconButton>
            <Typography fontSize={14} fontFamily={'serif'} gutterBottom>
              {`You are going to deposit $${formik.values.amount + fee}`}
            </Typography>
          </Box>
          <Box p={1} />
          <Typography>
            {`Send exactly`} <span style={{ fontWeight: 900 }}>{`$${formik.values.amount + fee} `}</span> to
          </Typography>
          <TextField variant="outlined" size="small" fullWidth value={`${payment?.unique_id}`} disabled />
          <Box p={2} />
          <div style={{ height: 'auto', margin: '0 auto', maxWidth: 100, width: '100%' }}>
            <QRCode
              size={320}
              style={{ height: 'auto', maxWidth: '100%', width: '100%' }}
              value={`${payment?.unique_id}`}
              viewBox={`0 0 360 360`}
            />
          </div>
          <Box p={2} />
          <Button variant="contained" sx={{ textTransform: 'capitalize' }} onClick={() => handleSubmit()}>
            I have made payment
          </Button>
          <Box p={1} />
          <Typography fontSize={13} textAlign={'center'}>
            Your payment will be completed once it is confirmed by the admin. Confirmation time can vary and depends on
            the fee paid. All transactions are performed on the side of third-party payment providers.
          </Typography>
        </Box>
      )}
    </FormikProvider>
  );
}

export default DepositForm2;
