/* eslint-disable no-nested-ternary */
/* eslint-disable react/prop-types */
import { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { useFormik, FormikProvider } from 'formik';

import Box from '@mui/material/Box';
// material
// Third party
// Services
import {
  Button,
  Card,
  FormControl,
  FormHelperText,
  InputLabel,
  NativeSelect,
  OutlinedInput,
  TextField,
  Toolbar,
  Typography,
} from '@mui/material';
import toast from 'react-hot-toast';
import { mutate } from 'swr';
import OTPInput from 'react-otp-input';
import { useDispatch, useSelector } from 'react-redux';
import { setLoading } from '../../store/reducer/lifeCycle';
import APIService from '../../service';

function WithdrawalPinForm(props) {
  const { profile } = props;
  const dispatch = useDispatch();
  const [isReady, setReady] = useState(false);
  const [countdown, setCountdown] = useState(60); // Initial countdown time
  const [isTimerActive, setIsTimerActive] = useState(true); // Controls button visibility
  const { wallets } = useSelector((state) => state.auth);
  const { loading } = useSelector((state) => state.lifeCycle);

  const schema = Yup.object().shape({
    pin: Yup.string().required('OTP is required'),
  });

  const schema2 = Yup.object().shape({
    amount: Yup.number().min(10000, "Amount must be at least 10,000").required('Amount is required'),
    wallet: Yup.string().required('Crypto wallet is required'),
  });

  // Countdown logic
  useEffect(() => {
    let timer;

    if (isTimerActive && countdown > 0) {
      timer = setInterval(() => {
        setCountdown((prev) => prev - 1);
      }, 1000);
    } else if (countdown === 0) {
      setIsTimerActive(false); // Show the button when countdown ends
    }

    return () => clearInterval(timer); // Cleanup the timer
  }, [countdown, isTimerActive]);

  const formik = useFormik({
    initialValues: {
      pin: '',
    },
    validationSchema: schema,
    onSubmit: async () => {
      try {
        dispatch(setLoading(true));
        const payload = {
          user: profile?.id ?? profile?._id,
          pin: values.pin,
        };

        const response = APIService.post('/pin/verify', payload);

        toast.promise(response, {
          loading: 'Loading',
          success: () => {
            console.log("HJHD TESTING IT PUOT ");
            dispatch(setLoading(false));
            mutate('/auth/profile');
            setReady(true)
            return 'OTP Verified!';
          },
          error: (err) => {
            dispatch(setLoading(false));
            return err?.response?.data?.message || err?.message || 'Something went wrong. Try again!';
          },
        });
      } catch (error) {
        console.log(error);
        dispatch(setLoading(false));
      }
    },
  });

  const formik2 = useFormik({
    initialValues: {
      amount: '',
      wallet: '',
    },
    validationSchema: schema2,
    onSubmit: async (values) => {
      try {
        dispatch(setLoading(true));
        const payload = {
          type: "withdrawal",
          status: "pending",
          amount: values.amount,
          wallet: values.wallet,
        };

        const response = APIService.post('/request/create', payload);

        toast.promise(response, {
          loading: 'Loading',
          success: () => {
            dispatch(setLoading(false));
            mutate("auth/profile")
            return 'Withdrawal request sent successfully';
          },
          error: (err) => {
            dispatch(setLoading(false));
            return err?.response?.data?.message || err?.message || 'Something went wrong. Try again!';
          },
        });
      } catch (error) {
        console.log(error);
        dispatch(setLoading(false));
      }
    },
  });

  const { errors, touched, values, handleSubmit, setFieldValue, getFieldProps } = formik;

  return (
    <FormikProvider value={formik}>
      <Box component={Card} elevation={2} p={2}>
        {profile?.withdrawalStatus === 'approved' ? (
          <Box style={{ width: '100%', paddingBottom: 20 }}>
            <Box style={{ marginBottom: 20 }}>
              <Typography variant="body1" color="text.secondary">
                {`Enter the PIN that was sent to your email during PIN purchase`}
              </Typography>
            </Box>

            <OTPInput
              value={values.pin}
              onChange={(value) => {
                let otpValue = '';
                otpValue += value;
                setFieldValue('pin', value);
                if (otpValue.length === 4) {
                  // console.log('JBKJA jyJYA');
                  return handleSubmit();
                }
              }}
              inputType="number"
              shouldAutoFocus
              numInputs={4}
              inputStyle={{
                width: '100%',
                height: 50,
                marginRight: 10,
                marginLeft: 10,
                borderRadius: 8,
                fontSize: 18,
              }}
              renderSeparator={<span>-</span>}
              renderInput={(props) => (
                <input
                  disabled={loading}
                  placeholder={`${Boolean(touched.otp && errors.otp)}` ? `Error` : ''}
                  {...props}
                />
              )}
            />
          </Box>
        ) : profile?.withdrawalStatus === 'ready' || isReady ? (
          <Box>
            <Typography variant="body2" gutterBottom>
              Enter the amount you wish to withdraw and your preferred crypto wallet <br />{' '}
              <span>Do ensure you provide the right information</span>
            </Typography>
            <Toolbar />
            <Box style={{ width: '100%', paddingBottom: 20 }}>
              <TextField
                fullWidth
                type="number"
                required
                label="Withdrawal Amount"
                {...formik2.getFieldProps('amount')}
                error={Boolean(formik2.touched.amount && formik2.errors.amount)}
                helperText={formik2.touched.amount && formik2.errors.amount}
              />
              <Box p={1} />
              <FormControl fullWidth error={Boolean(formik2.touched.wallet && formik2.errors.wallet)} required>
                <InputLabel htmlFor="wallet" shrink margin="dense" sx={{ paddingRight: 24 }}>
                  Select wallet
                </InputLabel>
                <NativeSelect
                  input={
                    <OutlinedInput
                      label="Select wallet"
                      variant="outlined"
                      {...formik2.getFieldProps('wallet')}
                      id="wallet"
                    />
                  }
                  id="wallet"
                >
                  <option>Select wallet</option>
                  {wallets &&
                    wallets?.map((wallet) => (
                      <option key={wallet.id} value={wallet.id}>
                        {wallet?.name}
                      </option>
                    ))}
                </NativeSelect>
                <FormHelperText>{formik2.touched.wallet && formik2.errors.wallet}</FormHelperText>
              </FormControl>
              <Box p={2} />
              <Button
                fullWidth
                variant="contained"
                onClick={() => formik2.handleSubmit()}
                sx={{ textTransform: 'capitalize' }}
              >
                Proceed
              </Button>
            </Box>
          </Box>
        ) : (
          <></>
        )}
      </Box>
    </FormikProvider>
  );
}

export default WithdrawalPinForm;

WithdrawalPinForm.propTypes = {
  // location: PropType.object.isRequired,
  // toast: PropType.func.isRequired,
  // profileEmail: PropType.string,
  // fieldName: PropType.string,
  // fieldValue: PropType.string,
  // callback: PropType.func,
};
