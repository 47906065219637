/* eslint-disable no-nested-ternary */
import * as React from "react";
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarExport,
  GridToolbarDensitySelector,
} from "@mui/x-data-grid";

import { useSelector } from "react-redux";
import { Button, Dialog, DialogTitle, DialogContent, DialogActions, DialogContentText, Slide, Switch, IconButton } from '@mui/material';
import Iconify from "../../Iconify";
import { fNumber } from "../../../utils/formatNumber";
import useWallets from "../../../hooks/useWallets";
import CustomNoRowsOverlay from "../../no-data";
import InvestmentForm from "../../forms/InvestmentForm";


const Transition = React.forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

export default function WalletsTable() {
  const {profile, wallets } = useSelector((state) => state.auth);
  const [loading, setLoading] = React.useState(false);
  const [openConfirm, setOpenConfirm] = React.useState(false);
  const [openForm, setOpenForm] = React.useState(false);
  const [selected, setSelected] = React.useState();
  const [filteredWallets, setFilteredWallets] = React.useState(wallets ?? []);

  const [paginationModel, setPaginationModel] = React.useState({
    page: 0,
    pageSize: 25,
  });

  const { data: walletData, mutate } = useWallets(profile?._id ?? profile?.id);

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
        <GridToolbarDensitySelector />
        <GridToolbarExport />
       
      </GridToolbarContainer>
    );
  }

  React.useEffect(() => {
    if (wallets) {
      setFilteredWallets(wallets?.docs);
    }
  }, [wallets]);

  const columns = [
    {
      field: "name",
      headerName: "Platform",
      width: 130,
      flex: 1,
      renderCell: (params) => (
        <p style={{ fontSize: 14 }} > {params?.row?.name } </p>
      ),
    },
    {
      field: "balance",
      headerName: "Balance",
      width: 100,
      renderCell: (params) => (
        <p style={{ textTransform: "capitalize", fontSize: 14 }} > {`$${fNumber(params?.row?.balance)}`} </p>
      ),
    },
    {
      field: "walletAddress",
      headerName: "wallet Address",
      flex: 1,
      renderCell: (params) => (
        <p style={{ textTransform: "lowercase", fontSize: 14 }} > {`${params?.row?.walletAddress}`} </p>
      ),
    },
    {
      field: "default",
      headerName: "Default",
      width: 115,
      renderCell: (params) => (
        <Switch />
      ),
    },
    {
      field: "createAt",
      headerName: "Created On",
      width: 175,
      flex: 1,
      renderCell: (params) => (
        <p style={{ textTransform: "capitalize", fontSize: 14 }}>{`${new Date(
          params?.row?.createdAt
        ).toLocaleString("en-US", {
          weekday: "short",
          day: "numeric",
          month: "short",
          year: "numeric",
        })}`}</p>
      ),
    },
    {
      field: "id",
      headerName: "ACTIONS",
      width: 90,
      align: 'right',
      renderCell: (params) => <IconButton>
        <Iconify  icon={'nrk:more'} />
      </IconButton>
    },
  ];

  React.useEffect(() => {
    let active = true;

    (async () => {
      setLoading(true);
      if (walletData) {
        setFilteredWallets(walletData);
      }

      if (!active) {
        return;
      }

      setLoading(false);
    })();

    return () => {
      active = false;
    };
  }, [paginationModel.page, walletData]);

  return (
    <div style={{ height: "80vh", width: "100%" }}>
       <Dialog
        open={openConfirm}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => setOpenConfirm(false)}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>{'Important Notice!'}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            {`Are you sure you want to proceed with this investment? Risk level is ${selected?.riskLevel} and Return On Investment (ROI) is ${selected?.roi}%`}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenConfirm(false)}>Cancel</Button>
          <Button
            onClick={() => {
              setOpenConfirm(false);
              setOpenForm(true);
            }}
          >
            Proceed
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openForm}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => setOpenForm(false)}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>{'Investment Form'}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            {`You are expected to investment between $${selected?.minAmount} and $${selected?.maxAmount}`}
          </DialogContentText>
          <br />
          <br />
          <InvestmentForm setOpenModal={setOpenForm} data={selected} loading={loading} setLoading={setLoading} profile={profile} />
        </DialogContent>
      </Dialog>
      {wallets && filteredWallets && (
        <DataGrid
          sx={{ padding: 1 }}
          rows={filteredWallets}
          columns={columns}
          paginationMode="server"
          pageSizeOptions={[5]}
          rowCount={wallets?.length}
          paginationModel={paginationModel}
          loading={loading}
          components={{
            Toolbar: CustomToolbar,
            NoRowsOverlay: CustomNoRowsOverlay,
          }}
        />
      )}
    </div>
  );
}
