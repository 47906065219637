import PropType from 'prop-types';
import { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { useFormik, Form, FormikProvider } from 'formik';
import { useNavigate } from 'react-router-dom';
import { useSWRConfig } from 'swr';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
// material
import OtpInput from 'react-otp-input';
// Third party
// Services
import { Button, Card } from '@mui/material';
import APIService from '../../service';
// component

function VerifyOTPForm(props) {
  const { location, toast, profileEmail, fieldName, fieldValue, callback } = props;
  const [loading, setLoading] = useState();
  const [countdown, setCountdown] = useState(60); // Initial countdown time
  const [isTimerActive, setIsTimerActive] = useState(true); // Controls button visibility

  const navigate = useNavigate();
  const { mutate } = useSWRConfig();

  const OTP_LENGTH = 4;

  const schema = Yup.object().shape({
    otp: Yup.number().required('OTP is required'),
  });

  // Countdown logic
  useEffect(() => {
    let timer;

    if (isTimerActive && countdown > 0) {
      timer = setInterval(() => {
        setCountdown((prev) => prev - 1);
      }, 1000);
    } else if (countdown === 0) {
      setIsTimerActive(false); // Show the button when countdown ends
    }

    return () => clearInterval(timer); // Cleanup the timer
  }, [countdown, isTimerActive]);

  const handleResendOTP = async () => {
    try {
      setLoading(true);

      const response = APIService.post('/auth/send-otp', { emailAddress: location.state?.emailAddress || fieldValue });

      toast.promise(response, {
        loading: 'Loading',
        success: () => {
          setLoading(false);
          setCountdown(60); // Reset countdown
          setIsTimerActive(true); // Restart the timer
          return 'OTP sent successfully!';
        },
        error: (err) => {
          setLoading(false);
          return err?.response?.data?.message || err?.message || 'Something went wrong, try again.';
        },
      });
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const formik = useFormik({
    initialValues: {
      otp: '',
    },
    validationSchema: schema,
    onSubmit: async () => {
      setLoading(true);
      let payload;
      if (location.pathname === '/dashboard/overview' || location.pathname === '/dashboard/loan') {
        payload = {
          ...values,
          emailAddress: profileEmail,
          [fieldName]: fieldValue,
        };
      } else {
        payload = {
          ...values,
          emailAddress: location?.state?.emailAddress,
        };
      }

      const response = APIService.post('/auth/verify-otp', payload);

      toast.promise(response, {
        loading: 'Loading',
        success: () => {
          if (location?.state?.emailAddress && location?.state?.accessToken) {
            localStorage.setItem('accessToken', location?.state?.accessToken);
            localStorage.setItem('refreshToken', location?.state?.refreshToken);
            localStorage.setItem('loggedIn', 'yes');

            mutate('/auth/profile');
            navigate('/dashboard/overview', { state: location?.state?.emailAddress, replace: true });
          } else if (location?.state?.emailAddress) {
            navigate('/reset-password', { state: location?.state?.emailAddress, replace: true });
          } else {
            mutate('/auth/profile');
            callback(fieldName);
          }
          return 'OTP Verified!';
        },
        error: (err) => {
          setLoading(false);
          return err?.response?.data?.message || err?.message || 'Something went wrong, try again.';
        },
      });
    },
  });

  const { errors, touched, values, handleSubmit, setFieldValue } = formik;

  return (
    <FormikProvider value={formik}>
      <Box component={Card} elevation={2} p={2}>
        <Form autoComplete="off" onSubmit={handleSubmit} style={{ width: '100%', paddingBottom: 20 }}>
          <Box style={{ marginBottom: 20 }}>
            <Typography variant="body1" color="text.secondary">
              {`We sent an OTP to your mail (${
                location.state?.emailAddress || fieldValue
              }). open your mail and enter the OTP sent to your mail.`}
            </Typography>
          </Box>

          <OtpInput
            value={values.otp}
            onChange={(value) => {
              let otpValue = '';
              otpValue += value;
              setFieldValue('otp', value);
              if (otpValue.length === OTP_LENGTH) {
                return handleSubmit();
              }
            }}
            inputType="number"
            shouldAutoFocus
            numInputs={OTP_LENGTH}
            inputStyle={{
              width: '100%',
              height: 50,
              marginRight: 10,
              marginLeft: 10,
              borderRadius: 8,
              fontSize: 18,
            }}
            renderSeparator={<span>-</span>}
            renderInput={(props) => (
              <input
                disabled={loading}
                placeholder={`${Boolean(touched.otp && errors.otp)}` ? `Error` : ''}
                {...props}
              />
            )}
          />
          <Box p={4} />
          {isTimerActive ? (
            <p>Resend OTP in: {countdown}s</p>
          ) : (
            <Button variant="contained" onClick={handleResendOTP} sx={{ textTransform: 'capitalize' }}>
              Resend OTP
            </Button>
          )}
        </Form>
      </Box>
    </FormikProvider>
  );
}

export default VerifyOTPForm;

VerifyOTPForm.propTypes = {
  location: PropType.object.isRequired,
  toast: PropType.func.isRequired,
  profileEmail: PropType.string,
  fieldName: PropType.string,
  fieldValue: PropType.string,
  callback: PropType.func,
};
